import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import LabelDisplayRow from "../../../../../shared/cell/label/LabelDisplayRow";
import BillingRow from "@/portals/shared/cell/billing/BillingRow";
import StatusDisplayRow from "../../../../../shared/cell/status/StatusDisplayRow";

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.CU = new ContentUtils();
    this.SU = new StringUtils();
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      return;
    }
    if (this.model().isStateContentLoading()) {
      return;
    }

    if (!this.model().isStateContentLoaded()) {
      return;
    }

    this.renderHeader();
    this.renderBody();
    this.renderFooter();
  }


  renderHeader() {
    // this.add(new TitleDisplayRow().withTitle("Billing"));
    //
  }

  renderBody() {
    var buttonBoxRow = new RowDisplayRow()
      .withChild(new SpacerDisplayRow().withWidth(10));

    if (this.model().isAdmin()) {
      buttonBoxRow
        .withChild(new ButtonDisplayRow()
          .withName("Remove")
          .withField("RemoveCcButton")
          .withKey("RemoveCcButton")
          .withWidth(2).withId(this.model().settings().card().id()))
    } else {
      buttonBoxRow
        .withChild(new SpacerDisplayRow().withWidth(2));
    }

    buttonBoxRow
      .withChild(new ButtonDisplayRow()
        .withName("Update")
        .withField("UpdateCcButton")
        .withKey("UpdateCcButton")
        .withWidth(3).withId(this.model().settings().card().id()));
    var buttonBox = new BoxDisplayRow().withChild(buttonBoxRow);

    let company = this.model().company();
    var status = null;
    if (company.isPaymentMethodCreditCard()) {
      if (!company.hasCard()) {
        status = new StatusDisplayRow().withLabelWidth(0).withValue("warning")
          .withObject("You must add a Credit Card before your contract start day of " + company.contractStartDate().displayAs_YYYY_MM_DD());
      }
    }

    var paymentBox = new BoxDisplayRow()
      .withChild(new RowDisplayRow()
        .withChild(new BoxDisplayRow().withWidth("15").withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Payment"))
          .withChild(new BoxDisplayRow()
            .withChild(status)
          )
          .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().card().find().name()))
          .withChild(new LabelValueDisplayRow().withLabel("Number").withValue(this.model().settings().card().find().number()))
          .withChild(new LabelValueDisplayRow().withLabel("Expiry").withValue(this.model().settings().card().find().expiry()))
          .withChild(new StringDisplayRow().withFontSize("small")
                          .withValue("We do NOT accept American Express Cards").withFontSize("xsmall"))
          .withChild(buttonBox)
        )
      );


    var detailsBox = new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("15").withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Invoice Details"))
        .withChild(new StringDisplayRow().withValue("This information will appear on your invoice"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Billing Company").withSubtitle(true))
          .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().companyName()))
        )

        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Billing Contact").withSubtitle(true))
          .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().contactName()))
          .withChild(new LabelValueDisplayRow().withLabel("Email").withValue(this.model().settings().contactEmail()))
          .withChild(new LabelValueDisplayRow().withLabel("Phone").withValue(this.model().settings().contactPhone()))
          .withChild(new LabelValueDisplayRow().withLabel("Address").withValue(this.model().settings().address().find().location()))
        )

        .withChild(new BoxDisplayRow().withBorder(true)
          .withChild(new TitleDisplayRow().withTitle("Invoice Notification Emails and Purchase Order #").withSubtitle(true))
          .withChild(new BoxDisplayRow().withBorder(true)
            .withChild(new StringDisplayRow().withValue("Invoices sent via email the first week of the month"))
          )
          .withChild(new LabelValueDisplayRow().withLabel("Email To").withValue(this.model().settings().emailTo()))
          .withChild(new LabelValueDisplayRow().withLabel("PO").withValue(this.model().settings().purchaseOrder()))
        )
        .withChild(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth(12))
          .withChild(new ButtonDisplayRow().withName("Update").withField("UpdateButton").withKey("UpdateButton")
            .withWidth(3).withId(this.model().settings().id()))
        )
        ))
    );

    var planBox = this.addPlanInformation();
    var creditBox = this.addCreditInformation();

    var leftKids = [];
    leftKids.push(paymentBox);
    leftKids.push(detailsBox);
    leftKids.push(planBox);
    leftKids.push(creditBox);

    var rightKids = [];
    if (this.model().isStateContentLoaded()) {
      rightKids.push(new TitleDisplayRow().withTitle("Invoices"))
      rightKids.push(this.renderRightHeader());
      rightKids.push(this.renderRightBody());
      rightKids.push(this.renderCells());
      rightKids.push(this.renderRightFooter());
    } else {
      rightKids.push(new TitleDisplayRow().withTitle("Invoices"))
      rightKids.push(this.renderRightHeader());
      rightKids.push(new LoadingDisplayRow().withMessage("Loading invoices"))
      rightKids.push(this.renderRightFooter());
    }

    var leftRightBox = new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("6").withChildren(leftKids))
      .withChild(new BoxDisplayRow().withWidth("9")
        .withChild(new BoxDisplayRow()
          .withChild(new BoxDisplayRow()
            .withChild(new BoxDisplayRow().withBorder(true)
              .withChildren(rightKids)
            )
          )
        )
      );
    this.add(leftRightBox);
  }

  addPlanInformation() {
    var rows = [];
    var company = this.model().company();
    var settings = company.invoiceSettings();

    if (this.model().hasFeatureVehicles()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Vehicle Storage Fee")
        .withValue("$" + this.model().priceVehicles() + " per vehicle or trailer per month"))
    }

    if (this.model().hasFeatureInspections()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Vehicle Inspections")
        .withValue("$" + this.model().priceInspections() + " per new Vehicle Inspection"))
    }

    if (this.model().hasFeatureTimesheets()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Hours of Service")
        .withValue("$" + this.model().priceTimesheets() + " per On Duty Day (No charge for days recorded as Off Duty all day)"))
    }

    if (this.model().hasFeatureEquipment()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Equipment Storage Fee")
        .withValue("$" + this.model().priceEquipment() + " per piece of equipment per month"))
    }

    if (this.model().hasFeatureEquipmentInspections()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Equipment Inspections")
        .withValue("$" + this.model().priceEquipmentInspections() + " per new Equipment Inspection"))
    }

    rows.push(new LabelValueDisplayRow().withLabel("Charged").withValue(company.billingPeriodDisplay() +  " using " + settings.paymentMethodDisplay()))

    var planBox = new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("15")
        .withChild(new TitleDisplayRow().withTitle("Plan Information"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChildren(rows)
        ))
      .withChild(new StringDisplayRow().withWidth("8")));

    return planBox;
  }

  addCreditInformation() {
    let rows = [];

    rows.push(new LabelValueDisplayRow()
      .withLabel("Overpayment ")
      .withValue("$" + this.model().overpayment()))

    rows.push(new LabelValueDisplayRow()
      .withLabel("Credit ")
      .withValue("$" + this.model().credit()))

    var planBox = new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new BoxDisplayRow().withWidth("15")
        .withChild(new TitleDisplayRow().withTitle("Overpayment Balance and Credit Information"))
        .withChild(new BoxDisplayRow().withBorder(true)
          .withChildren(rows)
        ))
      .withChild(new StringDisplayRow().withWidth("8")));

    return planBox;
  }

  renderFooter() {
    //
  }

  renderRight() {
  }

  renderRightBody() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Invoice #"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Issued On"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("2").withTitle("Total"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Due"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("4").withTitle("Paid"))
      );
    return box;
  }

  renderRightHeader() {
    var query = this.model().panel().$route.query;
    var totalItems = this.model().total();
    var headerBox = new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
          .withBorder(true)
          .withWidth(15)
          .withChild(new RowDisplayRow()
            .withChild(new LabelDisplayRow().withWidth("2").withLabel(""))
            .withChild(new SpacerDisplayRow().withWidth("10"))
            .withChild(new ButtonDisplayRow().withWidth("3").withName("Refresh").withField("Refresh"))
          )
          .withChild(new RowDisplayRow()
            .withChild(
              new BoxDisplayRow()
                .withWidth("15")
                .withBorder(true)
            )
          )
          .withChild(new RowDisplayRow()
            .withChild(new BoxDisplayRow()
              .withWidth("15")
              .withBorder(true)
              .withChild(new RowDisplayRow()
                .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("7").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "billing")))
                .withChild(new StringListUpdateRow().withWidth("5")
                  .withName("Items/Page")
                  .withField("PageSize")
                  .withShowLabel(false)
                  .withDefaultValue(this.model().pageSize(query, "billing"))
                  .withOptions(ContentUtils.PAGE_SIZES()))
                .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
                .withChild(new StringListUpdateRow().withWidth("4")
                  .withShowLabel(false)
                  .withName("Jump to Page")
                  .withField("PageIndex")
                  .withDefaultValue(query["page"] ? query["page"] : "1")
                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query, "billing"))))
                .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
              )
            )
          )
      );

    return headerBox;
  }

  renderRightFooter() {
    var query = this.model().panel().$route.query;
    var pages = this.model().pages();
    var totalItems = this.model().total();
    var footerBox =
      new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("6").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query, "billing")))
              .withChild(new StringDisplayRow().withWidth("3"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("4")
                .withShowLabel(false)
                .withName("Jump to Page")
                .withField("PageIndex")
                .withDefaultValue(query["page"] ? query["page"] : "1")
                .withOptions(pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        );
    return footerBox;
  }

  renderCells() {
    let box = new BoxDisplayRow().withBorder(false);
    let row = null;
    let even = true;
    let item = null;
    let list = this.model().invoices();
    for (var index = 0; index < list.length; index++) {
      item = list[index];
      row = new BillingRow().withInvoice(item);
      row
        .withIsAdmin(this.model().isAdmin())
        .withEven(even);
      box.withChild(row);
      even = !even;
    }
    return box;
  }
}