<template>
  <Box>

    <BillingUpdateRow v-if="this.showDialog === 'updateBilling'" :settingsId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></BillingUpdateRow>
    <CardUpdateRow v-if="this.showDialog === 'updateCard'" :cardId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></CardUpdateRow>
    <InvoiceDisplayRow v-if="this.showDialog === 'displayInvoice'" :invoiceId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></InvoiceDisplayRow>
    <WorkingDisplayRow v-if="this.showWorking" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></WorkingDisplayRow>

    <Row>
      <Column :width="15">
        <Box :key="redraw" borderStyle="rc-border-thin">

          <Row 
            v-for="(row, rowIndex) in viewData" 
            :key="rowIndex" >
            
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withEnterPressed="withEnterPressed"
                v-on:withButtonPressed="withButtonPressed"
                 />

          </Row>
        </Box>
      </Column>
    </Row>

  </Box>
  
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import BillingUpdateRow from '../display/billing/BillingUpdateRow.vue';
import CardUpdateRow from '../display/card/CardUpdateRow.vue';
import InvoiceDisplayRow from './display/DetailsDisplayRow.vue';
import ListViewModel from './ListViewModel.js';
import ListViewUtils from './ListViewUtils.js';

export default {
  name: "billing-list-view",
  components: {
    Column, Box, Row,
    CellFactory,
    BillingUpdateRow,
    CardUpdateRow,
    InvoiceDisplayRow,
  },
  props: {
  },
  data() {
    return {
      redraw: 1,
      isAdmin: false,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CellFactory: CellFactory,
      
      viewModel: null,
      viewData: [],
      showMissingRequirements: false,

      showUpdateBilling: false,
      showUpdateCard: false,
      showWorking: false,
      
      showDialog: null,
      selectedId: null,

      ListViewModel: ListViewModel,
      ListViewUtils: ListViewUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    $route (to, from){
      if (to && from) {
        if (to.query["action"] === "update") {
          this.selectedId = to.query["settingsId"];
          this.showDialog = "updateBilling";

        } else if (to.query["action"] === "updateCard") {
          this.selectedId = to.query["cardId"];
          this.showDialog = "updateCard";

        } else if (to.query["action"] === "displayInvoice") {
          this.selectedId = to.query["invoiceId"];
          this.showDialog = "displayInvoice"

        } else {
          if (StringUtils.isNotEmpty(this.showDialog)) {
            this.showDialog = "";

          } else {
            this.reloadPage();
          }
        }
      }
    },
  },
  mounted: function () {
    this.viewModel = new ListViewModel(this);
    this.viewModel.withStatePageLoading();
    this.viewModel.clearAndBuildTableData();
    this.viewModel.start();
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      ConnectionUtils.ensureCompanyLoaded(this, "billing/list");
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.paint();
      this.viewModel.loadItems(false);
    },
    
    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      var row = this.viewModel.findRowProgress();
      if (row) {
        row.withMessage(msg);
      }
      this.paint();
    },
    
    model: function() {
      return this.viewModel;
    },
    
    withEnterPressed(keyData) {
      if (keyData["title"]) {
        keyData["field"] = "Search"
        this.withButtonPressed(keyData);
      }
    },
    
    withValue: function(valueData) {
      this.model().withValue(valueData);

      if (valueData["field"] === "PageSize") {
        this.model().withPageSize(valueData, "billing");
        this.addQueryParams(valueData, "pageSize");
      }
      if (valueData["field"] === "PageIndex") {
        this.addQueryParams(valueData, "page");
      }
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);

      if (buttonData["field"] === "DetailsClose") {
        var query = Object.assign({}, this.$route.query);
        query = this.deleteDetails(query);
        this.$router.push({ query });
        if (buttonData["action"] == "reload") {
          this.reloadPageDelayed();
        }
      }

      if (buttonData["field"] === "UpdateButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "update";
        query["settingsId"] = this.Contents.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      if (buttonData["field"] === "Details") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "displayInvoice";
        query["invoiceId"] = this.Contents.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      if (buttonData["field"] === "UpdateCcButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "updateCard";
        query["cardId"] = this.Contents.baseEncode(buttonData["id"]);
        // this.$router.push({ name: 'operatorPaymentEdit', query });
        this.$router.push({ query });
      }

      if (buttonData["field"] === "Pdf") {
        this.printInvoice(buttonData);
      }

      if (buttonData["field"] === "RemoveCcButton") {
        this.removeCCAction(buttonData);
      }

      if (buttonData["field"] === "Refresh") {
        this.reloadPage();
      }

      if (buttonData["field"] === "NextPage") {
        const query = Object.assign({}, this.$route.query);
        if (query.page) {
          query.page = (Number(query.page) + 1) + "";
        } else {
          query.page = "2";
        }
        if (query.page <= this.model().pages().length) {
          this.$router.push({ query });
        }
      }

      if (buttonData["field"] === "PreviousPage") {
        const query = Object.assign({}, this.$route.query);
        if (query.page) {
          query.page = (Number(query.page) - 1) + "";
          if (query.page <= 0) {
            query.page = "1";
          }
          this.$router.push({ query });
        }
      }
    },
    
    printInvoice(buttonData) {
      var invoiceId = buttonData["id"];
      var invoice = this.domain.invoices().findById(invoiceId);
      var url = ContentUtils.escape(invoice.pdfUrl());
      url = url.replace("#", "%23");
      window.open(url, '_blank');
    },
  
    addQueryParams(valueData, key) {
      var query = Object.assign({}, this.$route.query);
      if (query == null) {
        query = {};
      }
      const value = valueData["value"];
      if (value && value != "null") {
        query[key] = value;
        if (key === "pageSize") {
          delete query.page
        }
      } else {
        delete query[key];
      }
      this.$router.push({ query });
    },
    
    removeCCAction(buttonData) {
      if (buttonData) {
        //
      }
      const company = this.viewModel.company();
      var event = this.domain
                    .events()
                    .invoice()
                    .removeCard(company);

      var context = {};
      context["model"] = this;
      if (!event) {
        return;
      }
      event.send(this.removeCCActionListener, context);
    },

    removeCCActionListener(response, context) {
      if (response, context) {
        //
      }
    },

    deleteDetails(query) {
      delete query.action;
      delete query.settingsId;
      delete query.cardId;
      return query;
    },

    reloadPageDelayed() {
      this.model()
        .withStateContentLoading()
        .clearAndBuildTableData()
        .done();
      setTimeout(this.reloadPage, 500);
    },

    reloadPage: function() {
      this.viewModel._list = [];
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.loadItems();
    },

    paint: function() {
      this.redraw++;
    },

    showDialogIfRequiredDelayed: function() {
      setTimeout(this.showDialogIfRequired, 200);
    },

    showDialogIfRequired: function() {
      var query = Object.assign({}, this.$route.query);
      if (query["action"]) {
        this.showDialog = query["action"];
        if (query["invoiceId"]) {
          this.selectedId = query["invoiceId"];
        }

        if (query["settingsId"]) {
          this.selectedId = query["settingsId"];
        }

        if (query["cardId"]) {
          this.selectedId = query["cardId"];
        }
      } 
    },
  },
}
</script>