<template>
  <Row>
    <Column :width="15" :key="redraw">
      <div id="detailsModal" class="rc-modal-dialog">
        <!-- Modal content -->
        <div class="rc-modal-content-dialog-wide">
          <div class="rc-modal-header-dialog">
            <Box >
              <Row 
                v-for="(row, rowIndex) in viewHeaderData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>

          <div class="rc-modal-body-dialog">
            <div class="rc-modal-body-inner-dialog rc-border-thin">
              <Box>
                <Row 
                  v-for="(row, rowIndex) in viewBodyData" 
                  :key="rowIndex" >
                    <CellFactory 
                      :rowData="row.data"
                      :showMissingRequirements="showMissingRequirements"
                      v-on:withValue="withFieldValue"
                      v-on:withButtonPressed="withButtonPressed"
                      />
                </Row>
              </Box>
            </div>
          </div>

          <div class="rc-modal-footer-dialog">
            <Box>
              <Row 
                v-for="(row, rowIndex) in viewFooterData" 
                :key="rowIndex" >
                  <CellFactory 
                    :rowData="row.data"
                    :showMissingRequirements="showMissingRequirements"
                    v-on:withValue="withFieldValue"
                    v-on:withButtonPressed="withButtonPressed"
                    />
              </Row>
            </Box>
          </div>
        </div>
      </div>
    </Column>
  </Row>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import Invoice from "@/domain/model/invoice/Invoice";
import LabelDisplayRow from "@/portals/shared/cell/label/LabelDisplayRow";
import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import EmailImageDisplayRow from "@/portals/shared/cell/image/email/EmailImageDisplayRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import LogoImageDisplayRow from '../../../../../../shared/cell/image/logo/LogoImageDisplayRow';
// import InvoiceSettings from '@/domain/model/invoice/InvoiceSettings';
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";

export default {
  name: "invoice-display-view",
  components: {
    Column, Row, 
    Box,
    CellFactory,
  },
  props: {
    invoiceId: { type: String, default: "" },
  },
  data() {
    return {
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      showMissingRequirements: false,
      viewHeaderData: [],
      viewBodyData: [],
      viewFooterData: [],

      kvps: {},
      error: null,
      StringUpdateRow: StringUpdateRow
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.setup();
    },

    //TODO - need to load employees 
    setup: function() {
      window.onclick = this.onClick;

      this.viewHeaderData = [];
      this.viewBodyData = [];
      this.viewFooterData = [];
      var id = ContentUtils.baseDecode(this.invoiceId);
      var invoice = this.domain.invoices().findById(id);
      var invoiceItems = invoice.items().find();
      var paymentItems = invoice.payments().find();
      var company = invoice.company().find();
      var invoiceSettings = company.invoiceSettings().find();

      // var card = invoiceSettings.card().find();

      this.viewHeaderData.push(new TitleDisplayRow().withTitle("Charge Credit Card"));

      if (this.error) {
        var box = new BoxDisplayRow().withBorder(true);
        box
          .withChild(new RowDisplayRow()
            .withChild(new SpacerDisplayRow().withWidth("1"))
            .withChild(new ErrorDisplayRow().withWidth("13").withError(this.error))
            .withChild(new SpacerDisplayRow().withWidth("1"))
          );
        this.viewBodyData.push(box);
      }

      let headerBox = new BoxDisplayRow();
      headerBox.addChild(new RowDisplayRow()
        .withChild(new BoxDisplayRow().withWidth(5)
          .addChild(new LogoImageDisplayRow())
          .addChild(new LabelDisplayRow().withLabel("ReadyChek Inc.").withFontSize("xlarge"))
          .addChild(new ValueDisplayRow().withValue("5-420 Erb St W. Suite #355"))
          .addChild(new ValueDisplayRow().withValue("Waterloo, Ontario N2L 6k6"))
        )
        .withChild(new BoxDisplayRow().withWidth(5)
          .addChild(new LabelDisplayRow().withLabel("Invoice").withFontSize("xxlarge").withAlignCentre())
        )
        .withChild(new BoxDisplayRow().withWidth(5)
          .addChild(new LabelValueDisplayRow().withLabel("Invoice #").withValue(invoice.number()))
          .addChild(new LabelValueDisplayRow().withLabel("Purchase Order").withValue(invoiceSettings.purchaseOrder()))
          .addChild(new LabelValueDisplayRow().withLabel("Invoice Date").withValue(invoice.createdDateV2().displayAs_YYYY_MM_DD()))
          .addChild(new LabelValueDisplayRow().withLabel("Term").withValue("Net 30"))
        )
      );
      this.viewBodyData.push(headerBox);

      let paidBox = new BoxDisplayRow().withWidth(7);
      if (invoice.paid()) {
        paidBox.addChild(new ValueDisplayRow().withValue("!! PAID !!").withFontSize("huge"))
        paidBox.addChild(new ValueDisplayRow().withValue("Recorded On " + invoice.paidDate().displayAs_YYYY_MM_DD()))
      }
      let invoiceToBox = new BoxDisplayRow();
      invoiceToBox.addChild(new RowDisplayRow()
        .addChild(
          new BoxDisplayRow().withWidth(8)
            .addChild(new LabelDisplayRow().withLabel("Invoice to:"))
            .addChild(new ValueDisplayRow().withValue(this.contactName(invoiceSettings, company, invoice)))
            .addChild(new ValueDisplayRow().withValue(this.contactCompany(invoiceSettings, company, invoice)))
            .addChild(new ValueDisplayRow().withValue(this.contactAddress(invoiceSettings, company, invoice)))
        ).addChild(paidBox)
      );
      this.viewBodyData.push(invoiceToBox);

      let detailsBox = new BoxDisplayRow().withBorder().withEven(false);
      let detailsTitleRow = new RowDisplayRow().withBackgroundTitle();
      detailsTitleRow.addChild(new TitleDisplayRow().withWidth(11).withTitle("Details").withAlignLeft());
      detailsTitleRow.addChild(new TitleDisplayRow().withWidth(1).withTitle("Rate").withAlignRight());
      detailsTitleRow.addChild(new TitleDisplayRow().withWidth(1).withTitle("Qty").withAlignRight());
      detailsTitleRow.addChild(new TitleDisplayRow().withWidth(2).withTitle("Amount").withAlignRight());
      detailsBox.addChild(detailsTitleRow);
      
      let item = null;
      let itemRow = null;
      let itemsSorted = invoiceItems.sort();
      for (let itemIndex = 0; itemIndex < itemsSorted.length; itemIndex++) {
        item = itemsSorted[itemIndex];
        itemRow = new RowDisplayRow().withBorder().withEven(false);
        itemRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(11).withValue(item.description()));
        itemRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue("$" + item.pricePerItemDisplay()));
        itemRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue(item.quantity()));
        itemRow.addChild(new StringDisplayRow().withAlignRight().withWidth(2).withValue(item.totalDisplay()));
        detailsBox.addChild(itemRow);
      }

      let discountRow = new RowDisplayRow().withBorder().withEven(true);
      discountRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(13).withValue(""));
      discountRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(1).withValue("Discount"));
      discountRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue("$" + this.discount(invoice)));
      detailsBox.addChild(discountRow);

      let subtotalRow = new RowDisplayRow().withBorder().withEven(true);
      subtotalRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(13).withValue(""));
      subtotalRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(1).withValue("Sub Total"));
      subtotalRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue("$" + this.subtotal(invoice)));
      detailsBox.addChild(subtotalRow);

      let taxRow = new RowDisplayRow().withBorder().withEven(true);
      taxRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(13).withValue(""));
      taxRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(1).withValue("Tax"));
      taxRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue("$" + this.taxes(invoice)));
      detailsBox.addChild(taxRow);

      let payment = null;
      let paymentRow = null;
      let paymentsSorted = paymentItems.sort();
      for (let paymentIndex = 0; paymentIndex < paymentsSorted.length; paymentIndex++) {
        payment = paymentsSorted[paymentIndex];
        paymentRow = new RowDisplayRow().withBorder().withEven(true);
        paymentRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(9).withValue(""));
        paymentRow.addChild(new StringDisplayRow().withAlignRight().withWidth(5).withValue("Payment recorded on " + payment.createdDateV2().displayAs_YYYY_MM_DD()));
        paymentRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue("$" + payment.amount()));
        detailsBox.addChild(paymentRow);
      }

      let totalRow = new RowDisplayRow().withBorder().withEven(true);
      totalRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(13).withValue(""));
      totalRow.addChild(new StringDisplayRow().withAlignLeft().withWidth(1).withValue("Total"));
      totalRow.addChild(new StringDisplayRow().withAlignRight().withWidth(1).withValue("$" + this.totalRemaining(invoice)));
      detailsBox.addChild(totalRow);

      this.viewBodyData.push(detailsBox);

      if (invoice.paid()) {
        let thankyouBox = new BoxDisplayRow()
          .addChild(new StringDisplayRow().withValue("Thank-you for your payment, we really appreciate your business!"))
        this.viewBodyData.push(thankyouBox);

      } else if (invoiceSettings.hasCardId()) {
        let cardBox = new BoxDisplayRow()
          .addChild(new StringDisplayRow().withValue("You are set up for automatic payments, your card will be charged on or after the 15th of the month. Notify ReadyChek if you feel there are any errors with this invoice."))
        this.viewBodyData.push(cardBox);

      } else {
        let payBox = new BoxDisplayRow()
          .addChild(new RowDisplayRow()
            .addChild(new BoxDisplayRow().withWidth(10)
                          .addChild(new LabelDisplayRow().withLabel("Wire payments to:"))
                          .addChild(new ValueDisplayRow().withValue("CIBC"))
                          .addChild(new ValueDisplayRow().withValue("120 The Boardwalk"))
                          .addChild(new ValueDisplayRow().withValue("Kitchener Ontario N2N 0B1"))
                          .addChild(new ValueDisplayRow().withValue("Beneficiary Name: ReadyChek Inc"))
                          .addChild(new ValueDisplayRow().withValue("Bank No: 010"))
                          .addChild(new ValueDisplayRow().withValue("Transit No: 03572"))
                          .addChild(new ValueDisplayRow().withValue("Account No: 1037110"))
                          .addChild(new ValueDisplayRow().withValue("SWIFT Code: CIBCCATT"))
                          .addChild(new ValueDisplayRow().withValue("HST 75331 6876 RT0001"))
                     )
            .addChild(new BoxDisplayRow().withWidth(5)
              .addChild(new LabelDisplayRow().withLabel("Pay Online:"))
              .addChild(new ButtonDisplayRow().withName("Pay by Credit Card").withBackgroundPrimaryAction().withField("PayOnline").withId(invoice.id()).withObject("Invoice"))
              .addChild(new LabelDisplayRow().withLabel("E-transfer payments to:"))
              .addChild(new ValueDisplayRow().withValue("finance@readychek.com"))
            )
          );
        this.viewBodyData.push(payBox);
      }

      let endBox = new BoxDisplayRow()
          .addChild(new StringDisplayRow().withValue("Questions? Please contact ReadyChek Finance at finance@readychek.com"))
          .addChild(new StringDisplayRow().withValue("or call 226-753-8131 "));
      this.viewBodyData.push(endBox);

      this.viewFooterData.push(new BoxDisplayRow()
                  .withBorder(true)
                  .withChild(
                    new RowDisplayRow()
                      .withChild(new ButtonDisplayRow().withWidth("3").withName("Close").withField("DetailsClose"))
                      .withChild(new StringDisplayRow().withWidth("12"))
                  )
              );
    },

    sendingScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new EmailImageDisplayRow())
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Sending charge details to QuickBooks, this may take a moment"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    doneScreen() {
      this.viewBodyData = [];
      this.viewBodyData.push(new BoxDisplayRow().withChild(new RowDisplayRow()
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                              .withChild(new BoxDisplayRow()
                                    .withWidth("11")
                                    .withChild(new EmailImageDisplayRow())
                                    .withChild(new StringDisplayRow()
                                                  .withMandatoryValue(true)
                                                  .withValue("Done!"))
                                  )
                              .withChild(new SpacerDisplayRow().withWidth("2"))
                            )
                          )
      this.viewFooterData = [];
      this.paint();
    },

    paint: function() {
      this.redraw++;
    },

    onClick: function(event) {
      var modal = document.getElementById("detailsModal");
      if (event.target == modal) {
        this.goBack();
      }
    },
    
    withButtonPressed: function(buttonData) {
      this.showMissingRequirements = false;
      if (buttonData["field"] === "DetailsClose") {
        this.goBack();
      }
      if (buttonData["field"] === "Send") {
        this.chargeCreditCard();
      }
      if (buttonData["field"] === "PayOnline") {
        var invoiceId = buttonData["id"];
        var invoice = this.domain.invoices().findById(invoiceId);
        var url = ContentUtils.escape(invoice.paymentUrl());
        url = url.replace("#", "%23");
        window.open(url, '_blank');
      }
    },

    withFieldValue: function(valueData) {
      if (valueData) {
        //
      }
      this.showMissingRequirements = false;
    },

    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      if (msg) {
        //
      }
    },

    areRequirementsMet: function() {
      var met = true;
      return met;
    },

    chargeCreditCard: function() {
      this.showMissingRequirements = false;
      if (!this.areRequirementsMet()) {
        this.showMissingRequirements = true;
        return false;
      }

      var context = {}
      context["view"] = this;
      var id = ContentUtils.baseDecode(this.invoiceId);
      var invoice = this.domain.invoices().findById(id);
      
      this.sendingScreen();
      
      var event = this.domain.events().invoice().charge(invoice, this.kvps);
      event.with(Invoice.Fields.NUMBER, invoice.number());
      event.send(this.chargedCreditCard, context);
      return true;
    },
    
    chargedCreditCard: function(notification) {
      if (notification.error()) {
        this.error = notification.error();
        this.setup();
        this.paint();
      } else {
        this.doneScreen();
        setTimeout(this.goBack, 2000);
      }
    },

    goBack: function() {
      this.$router.back();
    },

    log() {
      return this.domain.logger();
    },

    discount(invoice) {
      return this.valueOrZero(invoice.discountDisplay());
    },

    subtotal(invoice) {
      return this.valueOrZero(invoice.totalBeforeTaxes());
    },

    totalRemaining(invoice) {
      return this.valueOrZero(invoice.totalRemaining());
    },

    taxes(invoice) {
      return this.valueOrZero(invoice.totalTaxes());
    },

    valueOrZero(amount) {
      if (StringUtils.isEmpty(amount)) {
        return "0.00";
      }
      return amount.toFixed(2)
    },

    contactName(invoiceSettings, company, invoice) {
      let owners = company.owners().active();
      let contactName = invoice.contactName();
      if (StringUtils.isNotEmpty(contactName)) {
        return contactName;
      }
      contactName = invoiceSettings.contactName();
      if (StringUtils.isEmpty(contactName)) {
        let employee = owners.first().find();
        let user = employee.user().find();
        contactName = user.fullName();
      }

      return contactName;
    },

    contactAddress(invoiceSettings, company, invoice) {
      let value = invoice.contactAddress();
      if (StringUtils.isNotEmpty(value)) {
        return value;
      }
      let address = invoiceSettings.address().find();
      return address.location();
    },

    contactCompany(invoiceSettings, company, invoice) {
      let value = invoice.contactCompany();
      if (StringUtils.isNotEmpty(value)) {
        return value;
      }
      let name = invoiceSettings.companyName();
      if (StringUtils.isNotEmpty(name)) {
        return name;
      }
      return company.name();
    }
  },
}
</script>